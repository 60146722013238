import React from 'react';

export const UserInfo = ({nextButton}) => {
    return(
        <div className="justify-center w-2/4 flex flex-col space-y-10">
            <div className="space-y-5 text-center">
                <h2 className="text-4xl">First, let's get a bit of info about you!</h2>
                <h5 className="text-xl">We need this to send your confirmation email</h5>
            </div>
            

            <div className="flex flex-col space-y-6">
                <input placeholder="Full Name" className="bg-transparent p-2 w-80 border-gray-400 border-b transition duration-300 ease-in-out focus:border-blue-400 hover:border-blue-300  outline-none" />
                <input placeholder="Email" className="bg-transparent p-2 w-80 border-gray-400 border-b transition duration-300 ease-in-out focus:border-blue-400 hover:border-blue-300 outline-none" />
                <input placeholder="Phone Number" className="bg-transparent p-2 w-80 border-gray-400 border-b transition duration-300 ease-in-out focus:border-blue-400 hover:border-blue-300 outline-none" />
                <input placeholder="DRE #" className="bg-transparent p-2 w-80 border-gray-400 border-b transition duration-300 ease-in-out focus:border-blue-400 hover:border-blue-300 outline-none" />

                <button onClick={nextButton} className="p-2 border border-gray-500 rounded w-20 transition duration-300 ease-in-out hover:bg-gray-300">Next</button>
            </div>
        </div>
    );
}
