import React from 'react';

export const Welcome = ({nextButton}) => {
    return(
        <div className="justify-center w-2/4 flex flex-col space-y-10">
            <div className="space-y-16 text-center">
                <h2 className="text-4xl">What can we do for you?</h2>
                <h5 className="text-xl">I want...</h5>
            </div>
            

            <div className="flex flex-col space-y-6">
                <select className="w-80 border border-gray-400 p-2">
                    <option>Choose...</option>
                    <option>Package A</option>
                    <option>Package B</option>
                    <option>Custom Package</option>
                </select>
                
                <button onClick={nextButton} className="p-2 border border-gray-500 rounded w-20 transition duration-300 ease-in-out hover:bg-gray-300">Next</button>
            </div>
        </div>
    );
}
