import React, { useState } from 'react';
import {UserInfo} from '../components/book-steps/userinfo';
import {Welcome} from '../components/book-steps/welcome';
import { Helmet } from 'react-helmet';
import KnockoutPhoto from '../images/KnockoutPhoto.png'

const BookPage = () => {
    const [itemCount, setItemCount] = useState(0);
    const items = [ (<Welcome nextButton={toggle} />), (<UserInfo nextButton={toggle} />), (<div>hi4</div>), (<div>hi5</div>), (<div>hi6</div>)];

    function toggle(e){
        setItemCount(itemCount + 1);
    }

    return(
        <div className="min-h-screen h-full" style={{ "backgroundColor" : "#eaeaea" }}>
            <Helmet>
                <title>Knockout Photo - Booking</title>
            </Helmet>

            <div className="grid grid-cols-3 justify-items-center items-center h-32 shadow-sm bg-white" style={{ "gridTemplateColumns" : "300px auto 300px" }}>
                <img src={KnockoutPhoto} className="w-72"  />
                <div className="text-5xl font-bold">Booking</div>
                <div>Cartlogo</div>
            </div>

            <div className="mt-10">
                <div className="flex flex-col items-center space-y-48 pb-10">
                    { items.filter((item, index) => index <= itemCount) }
                </div>
                
            </div>
        </div>
    );
}

export default BookPage;